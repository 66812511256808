import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { MainLayout, PostCard, PaginationHelper } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Row, Col, PageHeader, Typography } from 'antd'
const { Title, Text } = Typography
/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {

    const posts = data.posts.nodes
    const tag = pageContext.tag.node
    const title = data.site.siteMetadata.title
    const totalCount = data.posts.totalCount
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? `` : `s`
    } tagged with “${tag.title}”`

    return (
        <>
            <MetaData
                data={{tag:tag}}
                location={location}
                type="series"

            />
            <MainLayout>

                <PageHeader
                    ghost={true}
                    onBack={() => window.history.back()}
                />
                <Row
                    gutter={[16, 24]}
                    type="flex"
                    justify={`center`}
                >
                    <Col
                        xs={24}
                        sm={20}
                        md={20}
                        lg={20}
                        xl={20}
                    >
                        <Title
                            className={`homeTitle`}
                            level={1}
                            style={{ textAlign: `center` }}
                        >
                            { tag.title }
                        </Title>

                        <Title
                            className={`homeTitle`}
                            level={2}
                            style={{ marginTop: 0, textAlign: `center` }}
                        >
                            { tagHeader }
                        </Title>
                    </Col>
                </Row>

                <Row
                    gutter={[16, 24]}
                    type="flex"
                    justify={`space-around`}
                >
                    {posts.map((post, i) => (
                        <Col
                            key={i}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {/* The tag below includes the markup for each post - components/common/PostCard.js */}
                            <PostCard key={i} post={post} />
                        </Col>
                    ))}
                </Row>
                <PaginationHelper pageContext={pageContext} />

            </MainLayout>
        </>
    )
}

// Tag.propTypes = {
//     data: PropTypes.shape({
//         ghostTag: PropTypes.shape({
//             name: PropTypes.string.isRequired,
//             description: PropTypes.string,
//         }),
//         allGhostPost: PropTypes.object.isRequired,
//     }).isRequired,
//     location: PropTypes.shape({
//         pathname: PropTypes.string.isRequired,
//     }).isRequired,
//     pageContext: PropTypes.object,
// }

export default Tag

export const tagPageQuery = graphql`
  query TagPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    tagJson(id: { eq: $id }) {
        title
        description
        visible
        collectionType
        slug
    }
    posts: allMarkdownRemark(
        filter: {frontmatter: {collectionType: {eq: "post"}, public: {eq: true}, tags: {elemMatch: {id: {eq: $id}}} }}
        sort: { fields: [frontmatter___published_at], order: DESC }
    ) {
        nodes {
            ...MultiplePostFields
        }
        totalCount
    }
  }
`
